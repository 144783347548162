import React, { useEffect, useState } from "react";

import "./home.css";

import Product from "./Components/product";

function Home(props) {
  const [data, setData] = useState([]);
  

  const getData = async () => {
    const url = "https://fakestoreapi.com/products";

    let jsonData = await fetch(url);

    let parsedData = await jsonData.json();


    setData(parsedData);
  };

  useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  {
    /*const jewelery = data.filter((ele)=>{
  return ele.category == "jewelery";
 })*/
  }

  return (
    <div className="home">
      <img
        className="home-image"
        src="https://m.media-amazon.com/images/I/71yr9snSjYL._SX3000_.jpg"
        alt="xyz"
      />

      {data.map((ele) => {
        return (
          <div className="home-row" key={ele.image}>
            <Product
              title={ele.title ? ele.title : "xyz"}
              price={ele.price}
              image={ele.image}
              rating={ele.rating}
             
            />
          </div>
        );
      })}
    </div>
  );
}

export default Home;
