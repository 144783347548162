import React from "react";
import "./footer.css";

function Footer(){
   
        return(
            <div className="Footer-page">
                <h2> This is end of amazon site</h2>
                <h1>Thank you for visiting AMAZON WEBSITE...........</h1>
               
            </div>
        )
    }


export default Footer;
