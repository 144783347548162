import React, { useState } from "react";
import "./checkout.css";
import CheckoutProduct from "./Components/checkoutProduct";
import { Link } from "react-router-dom";
import SubTotal from "./Components/subTotal";
import { useStateValue } from "./Components/stateProvider";

function Checkout() {
  const [{ basket }, dispatch] = useStateValue();
  return (
    <div>
      {basket.length <= 0 ? (
        <div class="containers">
          <div class="card"></div>
          <div class="center">
            <img
              src="https://m.media-amazon.com/images/G/31/cart/empty/kettle-desaturated._CB424694257_.svg"
              alt=""
            />
            <h4>Your Cart Is Empty</h4>
            <h6>Add Item To It Now</h6>
            <Link to="/Home">
              <button routerLink="/Home" class="btn">
                Shop Now
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="checkout">
          <div className="checkout__left">
            <img
              className="checkout__Add"
              src="https://slp-statics.astockcdn.net/static_assets/staging/23summer/seo-blade-1/Footer_504508103.jpg?width=1555&format=webp"
            ></img>
            <div className="checkout_title">
              <h2>Your shopping basket </h2>
              {basket.map((item) => (
                <CheckoutProduct
                  title={item.title}
                  image={item.image}
                  price={item.price}
                  rating={item.rating}
                />
              ))}
            </div>
          </div>
          <div className="checkout__right">
            <SubTotal />
          </div>
        </div>
      )}
    </div>
  );
}

export default Checkout;
