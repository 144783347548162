import React, { createContext, useReducer, useContext } from "react";

//Prepares the data layar
export const StateContext = createContext();

// wrap our app and provides datalayar to all components
export const StateProvider = ({ reducer, children, initialState }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

//Pull information from the data layar
export const useStateValue = () => useContext(StateContext);
