import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { StateProvider } from './Components/stateProvider';
import reducer, { initialState } from './reducer';






ReactDOM.render(
<StateProvider initialState={initialState} reducer={reducer} >
    <App/>
    </StateProvider>
     ,document.getElementById('root')
);


