import React from "react";
import "./product.css";
import { useStateValue } from "./stateProvider";

function Product({id, title, image, price, rating }) {
  const [{ basket }, dispatch] = useStateValue();

  const addToBasket = () => {
    //dispatch the items into the data layar
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        id:id,
        title: title,
        image: image,
        price: price,
        rating: rating,
      },
    });
  };

  return (
    <div className="product">
      <div className=" product-info">
        <p className="description">{title}</p>
        <p className="product-price">
          <small className="rupees">₹</small>
          <strong className="Prices">{price}</strong>
        </p>
        <div className="product-rating">
          <p>Ratings</p>
          {Array(rating)
            .fill()
            .map((_, i) => (
              <p className="star">*</p>
            ))}
        </div>
      </div>
      <img className="product-image" src={image} alt="" />
      <button onClick={addToBasket} className="addtocart-btn">
        Add to cart
      </button>
    </div>
  );
}

export default Product;
