import React, { useState, useEffect } from "react";
import "./home.css";
import Product from "./product";


function Fashion(props) {
  const [data, setData] = useState([]);
  const fashion = data.filter((ele) => {
    return ele.category == "women's clothing";
  });

  const getData = async () => {
    const url = "https://fakestoreapi.com/products";

    let jsonData = await fetch(url);

    let parsedData = await jsonData.json();

    console.log(parsedData);

    setData(parsedData);
  };

  useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="home">
      
        <img
          className="home-image"
          src="https://m.media-amazon.com/images/I/71ENubGGdaL._SX3000_.jpg"
          alt=""
        />
        {fashion.map((ele) => {
          return (
            <div className="home-row" key={ele.image}>
              <Product
                title={ele.title ? ele.title : "xyz"}
                price={ele.price}
                image={ele.image}
                rating={ele.rating}
              />
            </div>
          );
        })}
      </div>
    
  );
}

export default Fashion;
