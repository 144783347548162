import React from "react";
import Product from "./product";

function Mobile() {
  return (
    <div className="home">
      <div className="home-container">
        <img
          className="home-image"
          src="https://images-eu.ssl-images-amazon.com/images/G/31/img21/Wireless/sabikhit/catpage_P3/_finalP2_PC.jpg"
          alt=""
        />

        <div className="home-row">
          <Product
            title="Redmi 9 activ | Starting INR 8,499"
            price={8490}
            image="https://m.media-amazon.com/images/I/416SpYgTVYL._AC_SX226_.jpg"
            rating={5}
          />

          <Product
            title="OnePlus Nord CE 5G (Charcoal Ink, 8GB RAM, 128GB Storage)"
            price={29999.99}
            image="https://m.media-amazon.com/images/I/71LRBr1aLNS._SL1500_.jpg"
            rating={4}
          />

          <Product
            title="Apple iPhone 11 (64GB) - Black"
            price={59499.99}
            image="https://images-eu.ssl-images-amazon.com/images/I/41FqG3cHV9L._SY445_SX342_QL70_FMwebp_.jpg"
            rating={5}
          />
        </div>

        <div className="home-row">
          <Product
            title="Samsung Galaxy M12 (White,4GB RAM, 64GB Storage)"
            price={9499.99}
            image="https://m.media-amazon.com/images/I/71Y8rH2cJiL._AC_UY218_.jpg"
            rating={5}
          />
          <Product
            title="OnePlus Nord CE 5G (Charcoal Ink, 8GB RAM, 128GB Storage)"
            price={29999.99}
            image="https://m.media-amazon.com/images/I/71LRBr1aLNS._SL1500_.jpg"
            rating={4}
          />

          <Product
            title="Vivo Y73 (Diamond Flare, 8GB RAM, 128GB Storage) "
            price={19999.99}
            image="https://m.media-amazon.com/images/I/511oJE35CxS._AC_UY218_.jpg"
            rating={5}
          />
          <Product
            title="Redmi 10 (Prime Phantom Black 6GB RAM 128GB ROM )"
            price={14999.99}
            image="https://m.media-amazon.com/images/I/817clKAKcqL._AC_UY218_.jpg"
            rating={5}
          />
        </div>

        <div className="home-row">
          <Product
            title="OnePlus Nord CE 5G (Charcoal Ink, 8GB RAM, 128GB Storage)"
            price={29999.99}
            image="https://m.media-amazon.com/images/I/71LRBr1aLNS._SL1500_.jpg"
            rating={4}
          />
          <Product
            title="Mi 11X 5G Cosmic Black 6GB RAM 128GB ROM "
            price={27999.99}
            image="https://m.media-amazon.com/images/I/71VQXjN6R7S._SL1500_.jpg"
            rating={5}
          />

          <Product
            title="Apple iPhone 11 (64GB) - Black"
            price={59999.99}
            image="https://images-eu.ssl-images-amazon.com/images/I/41FqG3cHV9L._SY445_SX342_QL70_FMwebp_.jpg"
            rating={5}
          />
        </div>

        <div className="home-row">
          <Product
            title="Samsung Galaxy S21 5G (Phantom Violet, 8GB, 128GB Storage)"
            price={54999.99}
            image="https://m.media-amazon.com/images/I/91C0EYR0q5L._AC_UY218_.jpg"
            rating={5}
          />

          <Product
            title="Apple iPhone 11 (128GB) - White"
            price={69999.99}
            image="https://images-eu.ssl-images-amazon.com/images/I/41FqG3cHV9L._SY445_SX342_QL70_FMwebp_.jpg"
            rating={5}
          />

          <Product
            title="Apple iPhone 11 (64GB) - Silver"
            price={59999.99}
            image="https://images-eu.ssl-images-amazon.com/images/I/41FqG3cHV9L._SY445_SX342_QL70_FMwebp_.jpg"
            rating={5}
          />
          <Product
            title="Samsung Galaxy S21 5G (Phantom Violet, 8GB, 128GB Storage)"
            price={54999.99}
            image="https://m.media-amazon.com/images/I/91C0EYR0q5L._AC_UY218_.jpg"
            rating={5}
          />
          <Product
            title="Apple iPhone 11 (32GB) - Black"
            price={39999.99}
            image="https://images-eu.ssl-images-amazon.com/images/I/41FqG3cHV9L._SY445_SX342_QL70_FMwebp_.jpg"
            rating={3}
          />
        </div>

        <div className="home-row">
          <Product
            title="Apple iPhone 11 (64GB) - Black"
            price={59999.99}
            image="https://images-eu.ssl-images-amazon.com/images/I/41FqG3cHV9L._SY445_SX342_QL70_FMwebp_.jpg"
            rating={5}
          />

          <Product
            title="Apple iPhone 11 (32GB) - Blue"
            price={39999.99}
            image="https://images-eu.ssl-images-amazon.com/images/I/41FqG3cHV9L._SY445_SX342_QL70_FMwebp_.jpg"
            rating={5}
          />

          <Product
            title="Samsung Galaxy S21 5G (Phantom Violet, 8GB, 128GB Storage)"
            price={54999.99}
            image="https://m.media-amazon.com/images/I/91C0EYR0q5L._AC_UY218_.jpg"
            rating={5}
          />

          <Product
            title="Apple iPhone 11 (128GB) - White"
            price={69999.99}
            image="https://images-eu.ssl-images-amazon.com/images/I/41FqG3cHV9L._SY445_SX342_QL70_FMwebp_.jpg"
            rating={5}
          />
        </div>
        <div className="home-row">
          <Product
            title="Samsung Galaxy S21 5G (Phantom Violet, 8GB, 128GB Storage)"
            price={54999.99}
            image="https://m.media-amazon.com/images/I/91C0EYR0q5L._AC_UY218_.jpg"
            rating={5}
          />

          <Product
            title="Apple iPhone 11 (64GB) - Black"
            price={59999.99}
            image="https://images-eu.ssl-images-amazon.com/images/I/41FqG3cHV9L._SY445_SX342_QL70_FMwebp_.jpg"
            rating={5}
          />

          <Product
            title="Apple iPhone 11 (128GB) - White"
            price={69999.99}
            image="https://images-eu.ssl-images-amazon.com/images/I/41FqG3cHV9L._SY445_SX342_QL70_FMwebp_.jpg"
            rating={5}
          />

          <Product
            title="Apple iPhone 11 (64GB) - Silver"
            price={59999.99}
            image="https://images-eu.ssl-images-amazon.com/images/I/41FqG3cHV9L._SY445_SX342_QL70_FMwebp_.jpg"
            rating={5}
          />

          <Product
            title="Apple iPhone 11 (32GB) - Black"
            price={39999.99}
            image="https://images-eu.ssl-images-amazon.com/images/I/41FqG3cHV9L._SY445_SX342_QL70_FMwebp_.jpg"
            rating={3}
          />
        </div>
      </div>
    </div>
  );
}

export default Mobile;
