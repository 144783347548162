import React from "react";
import "./list.css";

function List(){
   
        return(
            <div className="List">
                <h1>hello guys this is List page !</h1>
            </div>
        )
    }


export default List;